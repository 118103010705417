import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Box sx={{ bgcolor: '#f9f9f9', py: 6 }}>
            <Container maxWidth="md">
                <Typography variant="h4" component="h1" sx={{ fontWeight: 700, mb: 4, textAlign: 'center' }}>
                    Política de Privacidade
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    A sua privacidade é importante para nós. É política da <strong>Vext</strong> respeitar a sua privacidade em relação a qualquer informação que possamos coletar no site Vext, e outros sites que possuímos e operamos.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, os protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    Uso do Google AdSense e Cookies
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você. Para mais informações sobre o Google AdSense, consulte as{' '}
                    <Link href="https://support.google.com/adsense/answer/1348695?hl=pt" target="_blank" rel="noopener" underline="hover">
                        FAQs oficiais sobre privacidade do Google AdSense
                    </Link>.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados por este site foram projetados para garantir que você forneça os anúncios mais relevantes, rastreando anonimamente seus interesses e apresentando conteúdos similares.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    Compromisso do Usuário
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    O usuário se compromete a fazer uso adequado dos conteúdos e da informação que a <strong>Vext</strong> oferece no site e com caráter enunciativo, mas não limitativo:
                </Typography>

                <Typography variant="body1" sx={{ pl: 2, mb: 2 }}>
                    A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé e à ordem pública;
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 2 }}>
                    B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, jogos de azar, pornografia ilegal, apologia ao terrorismo ou contra os direitos humanos;
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 2 }}>
                    C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) da <strong>Vext</strong>, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software capazes de causar danos.
                </Typography>

                <Typography variant="body1" sx={{ mt: 4, color: 'gray' }}>
                    Esta política é efetiva a partir de <strong>22 de novembro de 2024</strong>.
                </Typography>
            </Container>
        </Box>
    );
};

export default PrivacyPolicy;