import React, { useState, useEffect, useRef } from "react";
import './style.css';
import NavBar from "../../components/NavBar";
import { ThemeProvider } from "@mui/material";
import monochrome from "../../Themes/Monochrome";
import Footer from "../../sections/Home/Footer";
import api from "../../services/api";
import Carousel from "../../sections/PropertyDetails/Carousel";
import MoreCarousel from "../../sections/PropertyDetails/MoreCarousel";
import { useParams } from "react-router-dom";
import Details from "../../sections/PropertyDetails/Details";
import { Contact } from "../../sections/Home/Contact";

const PropertyDetails = () => {
  const { secureId } = useParams();
  const [propertyData, setPropertyData] = useState<any>(null);
  const [carouselData, setCarouselData] = useState<any>([]);
  const [contactDialogOpen, setContactDialogOpen] = useState(false); // Gerenciar estado do diálogo

  const emptyRefs = {
    header: useRef(null),
    partners: useRef(null),
    testimonials: useRef(null),
    discover: useRef(null),
  };

  const openContactDialog = () => setContactDialogOpen(true);
  const closeContactDialog = () => setContactDialogOpen(false);

  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await api.get(`list-property/${secureId}`);
        setPropertyData(response.data.property[0]);
      } catch (error) {
        console.error("Erro ao buscar dados do imóvel:", error);
      }
    };

    if (secureId) {
      fetchPropertyData();
    }
  }, [secureId]);

  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await api.get('list-sections');
        setCarouselData(response.data[0]);
      } catch (error) {
        console.error("Erro ao buscar dados dos carouséis:", error);
      }
    };

    fetchCarousels();
  }, []);

  return (
    <ThemeProvider theme={monochrome}>
      <NavBar refs={emptyRefs} openContactDialog={openContactDialog} />
      {propertyData && Array.isArray(propertyData.images) && (
        <Carousel images={propertyData.images} />
      )}
      {propertyData && <Details property={propertyData} />}
      <MoreCarousel title="Mais imóveis como este" id={carouselData.id} />
      <Footer />
      <Contact openDialog={contactDialogOpen} onCloseDialog={closeContactDialog} />
    </ThemeProvider>
  );
};

export default PropertyDetails;