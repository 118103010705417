import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style-partners.css';
import { ReactComponent as LocationIcon } from '../../../assets/images/nave.svg';
import { ReactComponent as DetailsSVG } from '../../../assets/images/detalhes.svg';
import api from "../../../services/api";
import truncateText from '../../../utils/truncateText';
import { Contact } from '../Contact';
import { ArrowForwardIos, ArrowBack, ArrowForward } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
interface CardProps {
  images: string[];
  title: string;
  subtitle: string;
  dimensions: string;
  suites: number;
  rooms: number;
  garages: number;
  city: string;
  state_abbreviation: string;
  neighborhood: string;
  secure_id: string;
}

const Card: React.FC<CardProps> = ({
  images,
  title,
  subtitle,
  city,
  state_abbreviation,
  neighborhood,
  dimensions,
  suites,
  rooms,
  garages,
  secure_id,
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    navigate(`/detalhes-do-imovel/${secure_id}`);
  };

  // Ícones personalizados para o carrossel interno de imagens
  const InnerPrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
    <Box
      className="inner-carousel-arrow inner-prev-arrow"
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '5px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ArrowBack sx={{ fontSize: '20px', color: '#000' }} />
    </Box>
  );

  const InnerNextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
    <Box
      className="inner-carousel-arrow inner-next-arrow"
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        right: '5px',
        transform: 'translateY(-50%)',
        zIndex: 2,
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ArrowForward sx={{ fontSize: '20px', color: '#000' }} />
    </Box>
  );

  // Configurações para o carrossel interno de imagens
  const imageSliderSettings = {
    dots: false,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: images.length > 1,
    prevArrow: <InnerPrevArrow />,
    nextArrow: <InnerNextArrow />,
    className: "inner-carousel",
  };
  

  return (
    <Box className="card">
      {images.length > 1 ? (
        <Slider {...imageSliderSettings}>
          {images.map((img, index) => (
            <Box key={index} sx={{ position: 'relative' }}>
              <img src={img} alt={`Imagem ${index + 1}`} className="carouselImage" />
            </Box>
          ))}
        </Slider>
      ) : (
        <Box>
          <img
            src={images[0] || "/placeholder.svg?height=800&width=600"}
            alt="Imagem única"
            className="carouselImage"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Box>
      )}
      <Box className="cardContent">
        <Box className="svgIconWrapper">
          <LocationIcon className="svgIcon" />
          <Typography className="iconText">{`${city} - ${state_abbreviation}`}</Typography>
        </Box>
        <Typography className="cardTitle">{title}</Typography>
        <Typography className="cardSubtitle">{subtitle}</Typography>
        <Box className="svgDetailsWrapper">
          <DetailsSVG className="detailsSvgIcon" />
        </Box>
        <Box className="detailssvg">
          <Box className="textPair">
            <Typography className="nunbsvg">{dimensions || '-'}</Typography>
            <Typography className="textsvg">m²</Typography>
          </Box>
          <Box className="textPair1">
            <Typography className="nunbsvg">{suites || '-'}</Typography>
            <Typography className="textsvg">{suites > 1 ? 'suítes' : 'suíte'}</Typography>
          </Box>
          <Box className="textPair">
            <Typography className="nunbsvg">{rooms || '-'}</Typography>
            <Typography className="textsvg">{rooms > 1 ? 'quartos' : 'quarto'}</Typography>
          </Box>
          <Box className="textPair">
            <Typography className="nunbsvg">{garages || '-'}</Typography>
            <Typography className="textsvg">{garages > 1 ? 'garagens' : 'garagem'}</Typography>
          </Box>
        </Box>
        <Box className="detailsButtonWrapper" style={{ width: '100%' }}>
          <Button className="detailsButton" onClick={handleDetailsClick}>
            VER DETALHES
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <Box
    className="custom-carousel-arrow slick-prev_2"
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '-25px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer !important',
      backgroundColor: '#C7FD06 !important', // Fundo verde
      borderRadius: '50% !important',
      width: '40px !important',
      height: '40px !important',
      display: 'grid !important',
      placeItems: 'center !important',
    }}
  >
    <ArrowBackIosNewIcon />
  </Box>
);

const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <Box
    className="custom-carousel-arrow slick-next_2"
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      right: '-25px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      backgroundColor: '#C7FD06 !important', // Fundo verde
      borderRadius: '50% !important',
      width: '40px !important',
      height: '40px !important',
      display: 'grid !important',
      placeItems: 'center !important',
    }}
  >
    <ArrowForwardIos sx={{ fontSize: '24px', color: '#000' }} />
  </Box>
);

const Partners: React.FC = () => {
  const [partnersData, setPartnersData] = useState<CardProps[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const fetchPartnersData = async () => {
      try {
        const response = await api.get('list-sections/3');
        const partners = response.data.map((item: any) => ({
          images: item.images?.map((img: any) => img.path) || ["/placeholder.svg?height=800&width=600"],
          title: item.friendly_title,
          subtitle: `${item.property_type} - ${item.city}, ${item.state}.`,
          dimensions: item.area ? item.area.toString() : "N/A",
          neighborhood: item.neighborhood ? item.neighborhood.toString() : "",
          city: item.city ? item.city.toString() : "",
          state_abbreviation: item.state_abbreviation ? item.state_abbreviation.toString() : "",
          suites: item.suites || 0,
          rooms: item.bedrooms || 0,
          garages: item.parking_spots || 0,
          secure_id: item.secure_id || '',
        }));
        setPartnersData(partners);
      } catch (error) {
        console.error("Error fetching partners data:", error);
      }
    };

    fetchPartnersData();
  }, []);

  const settings = React.useMemo(() => {
    const slidesToShow = Math.min(3, partnersData.length || 1);
    return {
      dots: false,
      infinite: partnersData.length > slidesToShow,
      autoplay: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      arrows: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      className: "custom-carousel",
      afterChange: (current: number) => setCurrentSlide(current),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(2, partnersData.length || 1),
            slidesToScroll: Math.min(2, partnersData.length || 1),
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
  }, [partnersData.length]);
  

  // Número total de slides
  const totalSlides = Math.ceil(partnersData.length / settings.slidesToShow);

  // Função para ir para um slide específico
  const goToSlide = (index: number) => {
    if (sliderRef) {
      sliderRef.slickGoTo(index * settings.slidesToScroll);
    }
  };
  if (!partnersData.length) {
    return null; // Retorna null se não houver propriedades
  }
  return (
    <Box className="partners">
      <Box className="mainContainer">
        <CardContent sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'transparent' }}>
          <Typography variant="h4" className="title-new">
            PARCEIROS
          </Typography>
          <Typography className="subtitle">
            Procurando empreendimentos a pronta entrega? Nossos clientes e parceiros vão te apresentar bons negócios.
          </Typography>
        </CardContent>
        <Slider {...settings} ref={(slider) => setSliderRef(slider)}>
          {partnersData.map((partner, index) => (
            <div key={index} className="custom-slide">
              <Card
                images={partner.images}
                title={truncateText(partner.title || '', 25)}
                subtitle={truncateText(partner.subtitle || '', 100)}
                city={partner.city}
                state_abbreviation={partner.state_abbreviation}
                neighborhood={partner.neighborhood}
                dimensions={partner.dimensions}
                suites={partner.suites}
                rooms={partner.rooms}
                garages={partner.garages}
                secure_id={partner.secure_id}
              />
            </div>
          ))}
        </Slider>

        {/* Bolinhas de navegação customizadas */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          {[...Array(totalSlides)].map((_, index) => (
            <Button
              key={index}
              variant={
                index === Math.floor(currentSlide / settings.slidesToScroll)
                  ? 'contained'
                  : 'outlined'
              }
              size="small"
              sx={{
                minWidth: 'auto',
                width: 12,
                height: 12,
                p: 0,
                mx: 0.5,
                borderRadius: '50%',
                borderColor: index === Math.floor(currentSlide / settings.slidesToScroll) ? '#C7FD06' : 'grey.400',
                bgcolor: index === Math.floor(currentSlide / settings.slidesToScroll) ? '#C7FD06' : '#8D8D8D',
                '&:hover': {
                  bgcolor: index === Math.floor(currentSlide / settings.slidesToScroll) ? '#B3E305' : '#8D8D8D',
                  borderColor: index === Math.floor(currentSlide / settings.slidesToScroll) ? '#B3E305' : 'grey.600',
                },
              }}
              onClick={() => goToSlide(index)}
              aria-label={`Ir para o slide ${index + 1}`}
            >
              <Box component="span" sx={{ display: 'none' }}>
                Slide {index + 1}
              </Box>
            </Button>
          ))}
        </Box>

        <Button className="mainButton" onClick={() => setOpenDialog(true)}>
          Saiba mais
        </Button>
        <Contact openDialog={openDialog} onCloseDialog={() => setOpenDialog(false)} />
      </Box>
    </Box>
  );
};

export default Partners;