import React, { useState } from 'react';
import { Grid, Typography, Box, Button, Container } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import './style.css';
import { Contact } from '../../Home/Contact';

// Import the icons
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import BedIcon from '@mui/icons-material/Bed';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import BathtubIcon from '@mui/icons-material/Bathtub';
import Garage from '../../../assets/icons/Garage';
import Bathroom from '../../../assets/icons/Bathroom';
import Room from '../../../assets/icons/Room';
import Mts from '../../../assets/icons/Mts';
import SvgContainer from '../../../components/SvgContainer';

const Details = ({ property }: any) => {
    const [expanded, setExpanded] = useState(false);
    const [openContactDialog, setOpenContactDialog] = useState(false);
    const url = `${window.location.host}/detalhes-do-imovel/`;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const toggleContactDialog = () => {
        setOpenContactDialog(!openContactDialog);
    };

    const truncatedDescription =
        property.description.length > 300
            ? property.description.substring(0, 300) + '...'
            : property.description;

    return (
        <>
            <Box sx={{ width: '1920px', maxWidth: '100%', padding: '20px', height: '800px', position: 'absolute', top: '400px', left: '50%', transform: 'translateX(-50%)' }}>
                <SvgContainer imgHeight="500" />
            </Box>
            <Container maxWidth="lg" sx={{ my: '60px', position: 'relative', zIndex: 2 }}>
                <Box sx={{ backgroundColor: '#fff', color: '#c7fd06', padding: '20px' }}>
                    <Grid container spacing={2} justifyContent="space-between" style={{ zIndex: 2 }}>

                        <Grid item xs={12} md={7}>
                            {/* Icons for property features */}
                            <Grid container sx={{ margin: '20px 0' }}>
                                {property.area && (
                                    <Grid item xs={6} sm={3}>
                                        <Box display="grid" sx={{ placeItems: 'center' }}>
                                            <Mts />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {property.area} m²
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {property.bedrooms && (
                                    <Grid item xs={6} sm={3}>
                                        <Box display="grid" sx={{ placeItems: 'center' }}>
                                            <Room />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {property.bedrooms} quartos
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {property.suites && (
                                    <Grid item xs={6} sm={3}>
                                        <Box display="grid" sx={{ placeItems: 'center' }}>
                                            <Bathroom />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {property.suites} suítes
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {property.parking_spots && (
                                    <Grid item xs={6} sm={3}>
                                        <Box display="grid" sx={{ placeItems: 'center' }}>
                                            <Garage />
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {property.parking_spots} vagas
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            {/* Informações sobre o imóvel */}

                            <Typography
                                color="black"
                                component="div"
                                sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                            >
                                {property.friendly_title}
                            </Typography>
                            <Typography
                                color="black"
                                sx={{ marginTop: '10px', fontWeight: 'bold' }}
                            >
                                Sobre este Imóvel
                            </Typography>
                            <CSSTransition
                                in={expanded}
                                timeout={300}
                                classNames="description"
                                unmountOnExit
                            >
                                <Typography
                                    color="black"
                                    variant="body1"
                                    sx={{ marginTop: '10px' }}
                                >
                                    {property.description}
                                </Typography>
                            </CSSTransition>
                            {!expanded && (
                                <Typography color="black" sx={{ marginTop: '10px' }}>
                                    {truncatedDescription}
                                </Typography>
                            )}
                            {property.description.length > 300 && (
                                <Typography
                                    color="black"
                                    sx={{
                                        marginTop: '10px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                    }}
                                    onClick={toggleExpanded}
                                >
                                    {expanded ? 'Mostrar Menos -' : 'Exibir Mais +'}
                                </Typography>
                            )}


                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="card-details">
                                <Typography className="p" sx={{ marginTop: '10px' }}>
                                    <span
                                        style={{ fontSize: '1rem', fontWeight: '400' }}
                                    >
                                        Valor:{' '}
                                    </span>
                                    <br /> R${' '}
                                    {parseFloat(property.sale_price).toLocaleString(
                                        'pt-BR',
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </Typography>
                                <Button
                                    variant="contained"
                                    className="button-details"
                                    onClick={toggleContactDialog}
                                >
                                    Entrar em contato
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* Diálogo de Contato */}
                <Contact
                    openDialog={openContactDialog}
                    onCloseDialog={toggleContactDialog}
                    initialMessage={`Olá, tenho interesse no imóvel anunciado no endereço eletrônico: ${url}${property.secure_id}`}
                />
            </Container>
        </>
    );
};

export default Details;
