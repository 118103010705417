import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import api from '../../../services/api';
import './style.css';

interface Office {
  name: string;
  latitude_id: string;
}

interface FormData {
  nome: string;
  celular: string;
  email: string;
  latitude_id: string;
  mensagem: string;
}

interface ContactProps {
  initialMessage?: string | null;
  openDialog: boolean;
  onCloseDialog: () => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#1a1a1a', // Fundo escuro para o diálogo
    color: '#c7fd06',
    border: '1px solid #c7fd06',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    borderRadius: '10px', // Bordas arredondadas
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: '#333', // Campo de entrada escuro
    color: '#c7fd06',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#c7fd06',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#c7fd06',
  },
  '& .MuiFilledInput-underline:before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.42)',
  },
  '&:hover .MuiFilledInput-underline:before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.87)',
  },
}));

const StyledButton = styled(Button)({
  background: 'linear-gradient(90deg, #c7fd06 0.31%, #779704 100.31%)', // Gradiente verde
  color: 'black',
  fontWeight: 'bold',
  '&:hover': {
    background: 'linear-gradient(90deg, #b3e505 0.31%, #5f7503 100.31%)',
  },
});

export function Contact({ openDialog, onCloseDialog, initialMessage = null }: ContactProps) {
  const [checked, setChecked] = useState<string>('');
  const [formData, setFormData] = useState<FormData>({
    nome: '',
    celular: '',
    email: '',
    latitude_id: '',
    mensagem: '',
  });
  const [offices, setOffices] = useState<Office[]>([]);
  const nomeRef = useRef<HTMLInputElement>(null);
  const celularRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const mensagemRef = useRef<HTMLInputElement>(null);
  const [celular, setCelular] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [hiddenMessage, setHiddenMessage] = useState<string>(''); // Informação oculta do imóvel

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await api.get('list-offices');
        setOffices(response.data);
      } catch (error) {
        console.error('Erro ao buscar escritórios', error);
      }
    };

    fetchOffices();
  }, []);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const office = offices.find((office) => office.name === event.target.name);
    setChecked(event.target.name);
    setFormData({
      ...formData,
      latitude_id: office ? office.latitude_id : '',
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const validateForm = (): boolean => {
    if (!formData.nome) {
      setSnackbarMessage('Por favor, preencha o campo nome.');
      setOpenSnackbar(true);
      nomeRef.current?.focus();
      return false;
    } else if (!formData.celular) {
      setSnackbarMessage('Por favor, preencha o campo celular.');
      setOpenSnackbar(true);
      celularRef.current?.focus();
      return false;
    } else if (!formData.email) {
      setSnackbarMessage('Por favor, preencha o campo email.');
      setOpenSnackbar(true);
      emailRef.current?.focus();
      return false;
    } else if (!formData.latitude_id) {
      setSnackbarMessage('Por favor, selecione um escritório.');
      setOpenSnackbar(true);
      return false;
    } else if (!formData.mensagem) {
      setSnackbarMessage('Por favor, escreva sua mensagem.');
      setOpenSnackbar(true);
      mensagemRef.current?.focus();
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const fullMessage = `${formData.mensagem}\n\n---\nInformações do imóvel:\n${hiddenMessage}`;
        const payload = { ...formData, mensagem: fullMessage };

        const response = await api.post('send-lead', payload);
        console.log('Formulário enviado:', response.data);
        setSnackbarMessage('Formulário enviado com sucesso!');
        setOpenSnackbar(true);
        onCloseDialog(); // Fecha o diálogo após enviar
      } catch (error) {
        setSnackbarMessage('Erro ao enviar o formulário.');
        setOpenSnackbar(true);
        console.error('Erro ao enviar dados do formulário', error);
      }
    }
  };

  const handleCelularChange = (event: ChangeEvent<HTMLInputElement>) => {
    const originalValue = event.target.value.replace(/\D/g, '');
    let maskedValue = '';

    if (originalValue.length > 10) {
      maskedValue = originalValue.replace(/^(\d{2})(\d{5})(\d{1,4}).*$/, '($1) $2-$3').substr(0, 15);
    } else if (originalValue.length > 6) {
      maskedValue = originalValue.replace(/^(\d{2})(\d{4})(\d{0,4}).*$/, '($1) $2-$3');
    } else if (originalValue.length > 2) {
      maskedValue = originalValue.replace(/^(\d{2})(\d{0,4}).*$/, '($1) $2');
    } else if (originalValue.length > 0) {
      maskedValue = originalValue.replace(/^(\d{0,2}).*$/, '($1');
    } else {
      maskedValue = '';
    }

    setCelular(maskedValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      celular: maskedValue,
    }));
  };

  useEffect(() => {
    if (initialMessage) {
      setHiddenMessage(initialMessage); // Armazena a mensagem inicial oculta
    }
  }, [initialMessage]);

    return (
        <Box>
            <StyledDialog open={openDialog} onClose={onCloseDialog} maxWidth="lg" fullWidth>
                <DialogTitle sx={{ color: '#c7fd06', fontWeight: 'bold', textAlign: 'center' }}>
                    Entre em contato
                </DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <InputLabel sx={{ color: '#c7fd06', mt: 2 }}>Nome</InputLabel>
                        <StyledTextField
                            inputRef={nomeRef}
                            name="nome"
                            value={formData.nome}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            variant="filled"
                        />
                        <InputLabel sx={{ color: '#c7fd06', mt: 2 }}>Celular</InputLabel>
                        <StyledTextField
                            inputRef={celularRef}
                            name="celular"
                            value={celular}
                            onChange={handleCelularChange}
                            fullWidth
                            margin="normal"
                            variant="filled"
                        />
                        <InputLabel sx={{ color: '#c7fd06', mt: 2 }}>Email</InputLabel>
                        <StyledTextField
                            inputRef={emailRef}
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            variant="filled"
                        />
                        <InputLabel sx={{ color: '#c7fd06', mt: 2 }}>Mensagem</InputLabel>
                        <StyledTextField
                            inputRef={mensagemRef}
                            name="mensagem"
                            value={formData.mensagem}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            variant="filled"
                        />
                        <Box sx={{ display: 'grid', justifyContent: 'start', mb: 2 }}>
                            {offices.map((office, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={checked === office.name}
                                            onChange={handleCheckboxChange}
                                            name={office.name}
                                            sx={{ color: '#c7fd06', '&.Mui-checked': { color: '#c7fd06' } }}
                                        />
                                    }
                                    label={office.name}
                                    sx={{ color: '#c7fd06' }}
                                />
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} sx={{ color: '#c7fd06' }}>Cancelar</Button>
                    <StyledButton type="submit" onClick={handleSubmit}>
                        Enviar
                    </StyledButton>
                </DialogActions>
            </StyledDialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}