import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#000',
            contrastText: '#fff',
        },
        secondary: {
            main: '#dc004e',
            contrastText: '#fff',
        },
        error: {
            main: '#d32f2f',
        },
        background: {
            default: '#f0f2f5',
            paper: '#ffffff',
        },
        text: {
            primary: '#000',
            secondary: '#555',
        },
    },
    typography: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.125rem',
        },
        
        body1: {
            fontSize: '1rem',
        },
    },

    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#c7fd06',
                    color: '#fff',
                    height: '90px',
                    display: 'grid',
                    placeItems: 'center',
                    boxShadow: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '0',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingLeft: '20px', // Padding esquerdo
                    paddingRight: '20px', // Padding direito
                },
                containedPrimary: {
                    color: '#fff',
                    
                },
            },
        },
    },
});

export default defaultTheme;
