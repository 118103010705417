// Discover.tsx
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Slider from 'react-slick';
import api from '../../../services/api';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';
import { Contact } from '../Contact';

interface Image { 
  id: number; 
  path: string 
}

export function Discover() {
  const [images, setImages] = useState<Image[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    api.get('/vext-universe')
      .then(res => setImages(res.data))
      .catch(() => console.error('Erro ao buscar imagens'));
  }, []);

  if (!images.length) {
    return null;
  }

  const slidesToShow = Math.min(3, images.length);
  const slidesToScroll = Math.min(1, images.length);

  // Definir a largura do carrossel conforme a quantidade de imagens
  const carouselWidth = images.length === 1 
    ? { xs: '100%', md: '35%' }  // Somente uma imagem: md = 50%
    : { xs: '100%', md: '65%' }; // Mais de uma imagem: md = 65%

  const settings = {
    infinite: images.length > 3,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow,
    slidesToScroll,
    centerPadding: '0%',
    initialSlide: 0,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1, centerPadding: '0%' } },
      { breakpoint: 768, settings: { slidesToShow: Math.min(2, images.length), slidesToScroll: 1, centerPadding: '0%' } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1, centerPadding: '0%' } },
    ]
  };

  return (
    <>
      <Box className="svg-container" sx={{ backgroundColor: '#C7FD06' }}>
        <Box className="content-img">
          <div className="light-effect" />
          <img src={`${process.env.PUBLIC_URL}/assets/images/any/diagonal-arrows.png`} alt="Setas diagonais" />
        </Box>
        
        <Box 
          className="banner-container" 
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between', // garante texto à esquerda e imagem à direita
            width: '100%',
            gap: 2
          }}
        >
          <Box sx={{ 
            width: { xs: '100%', md: '30%' }, 
            display: { xs: "flex", md: "block" }, 
            justifyContent: { xs: "center", md: "flex-start" } 
          }}>
            <Typography className='text-banner' variant="h4" sx={{ mb: 2 }}>
              descubra o <br /> universo vext!
            </Typography>
            <Button 
              className="banner-button" 
              variant="contained" 
              href="https://www.instagram.com/vextimob/" 
              target="_blank"
              sx={{float: { xs: 'none', md: 'left' }}} // flutua à esquerda somente em telas md+
            >
              Seguir
            </Button>
          </Box>

          <Box className="custom-carousel-discover" sx={{ width: carouselWidth, textAlign: { xs: 'center', md: 'right' } }}>
            <Slider {...settings}>
              {images.map((image) => (
                <Box key={image.id} sx={{ padding: '5px' }}>
                  <Box 
                    component="img"
                    src={image.path}
                    sx={{ 
                      width: '100%', 
                      height: { xs: '300px', md: '40vh' }, 
                      borderRadius: '10px' 
                    }} 
                  />
                </Box>
              ))}
            </Slider>
            <Button 
              className="banner-button-2" 
              variant="contained" 
              href="https://www.instagram.com/vextimob/" 
              target="_blank"
              sx={{float: { xs: 'none', md: 'right' }}} // botao à direita em md+
            >
              Seguir
            </Button>
          </Box>
        </Box>
      </Box>

      <Contact openDialog={openDialog} onCloseDialog={() => setOpenDialog(false)} />
    </>
  );
}
