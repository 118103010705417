import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import api from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import ChevronLeft from '../../../assets/icons/ChevronLeft';
import ChevronRight from '../../../assets/icons/ChevronRight';
import './style.css';
import truncateText from '../../../utils/truncateText';
import { Contact } from '../Contact';

interface Property {
  id: string;
  image: string;
  title: string;
  description: string;
  price: string;
}

interface ComponentProps {
  title: string;
  subTitle: string;
  id: string;
}

export default function Carousel({ title, subTitle, id }: ComponentProps) {
  const [properties, setProperties] = useState<Property[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openContactDialog, setOpenContactDialog] = useState<boolean>(false);
  const [contactMessage, setContactMessage] = useState<string>(''); // Mensagem personalizada
  const navigate = useNavigate();

  // Theme and responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemWidth = 458; // Width of each carousel item
  const gapSize = isMobile ? 0 : 458; // Gap is 0 on mobile devices
  const totalItemWidth = itemWidth + gapSize;

  // Touch event state variables
  const [startTouchX, setStartTouchX] = useState<number | null>(null);
  const [currentTranslateX, setCurrentTranslateX] = useState<number>(
    -currentIndex * totalItemWidth
  );

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get(`list-sections/${id}`);
        const allProperties: Property[] = response.data.map((item: any) => ({
          id: item.secure_id,
          image: item.images[0]?.path || '/placeholder.svg?height=800&width=600',
          title: item.friendly_title || 'Título não disponível',
          description: [
            item.property_type,
            `${item.city || ''}, ${item.state || ''}`,
            `${item.area ? `${item.area} m²` : ''}`,
            `${item.bedrooms ? `${item.bedrooms} quartos` : ''}`,
            `${item.suites ? `${item.suites} suítes` : ''}`,
            `${item.parking_spots ? `${item.parking_spots} vagas` : ''}`,
          ]
            .filter(Boolean) // Remove valores nulos ou vazios
            .join(', '),
          price: item.sale_price
            ? parseFloat(item.sale_price).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            : null,
        }));
        setProperties(allProperties);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    if (id) {
      fetchImages();
    }
  }, [id]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(timer);
  }, [properties, currentIndex]);

  useEffect(() => {
    setCurrentTranslateX(-currentIndex * totalItemWidth);
  }, [currentIndex, totalItemWidth]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? properties.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === properties.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleImageClick = (id: string) => {
    navigate(`/detalhes-do-imovel/${id}`);
  };

  const handleContactClick = () => {
    const currentProperty = properties[currentIndex];
    const url = `${window.location.host}/detalhes-do-imovel/${currentProperty.id}`;
    const hiddenMessage = `\nURL do imóvel: ${url}\nPreço: ${currentProperty.price}\nTítulo: ${currentProperty.title}\nDescrição: ${currentProperty.description}`;
    const userMessage = `Olá, tenho interesse no imóvel anunciado no endereço eletrônico "${url}". Gostaria de saber mais detalhes!`;

    setContactMessage(hiddenMessage); // Informações internas do imóvel
    setOpenContactDialog(true); // Abre o modal para a mensagem do usuário
  };

  // Touch event handlers
  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setStartTouchX(touch.clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (startTouchX === null) return;
    const touch = e.touches[0];
    const deltaX = touch.clientX - startTouchX;
    setCurrentTranslateX(-currentIndex * totalItemWidth + deltaX);
  };

  const handleTouchEnd = () => {
    if (startTouchX === null) return;
    const deltaX = currentTranslateX + currentIndex * totalItemWidth;
    if (deltaX > 50 && currentIndex > 0) {
      // Swiped right
      setCurrentIndex(currentIndex - 1);
    } else if (deltaX < -50 && currentIndex < properties.length - 1) {
      // Swiped left
      setCurrentIndex(currentIndex + 1);
    } else {
      // Not enough movement, reset position
      setCurrentTranslateX(-currentIndex * totalItemWidth);
    }
    setStartTouchX(null);
  };
  if (!properties.length) {
    return null;
  }
  return (
    <Box className="carousel" sx={{ width: '100%', bgcolor: '#ffffff', pt: 2 }}>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography className="joinville-text">{title}</Typography>
      </Container>

      <Container
        style={{ maxWidth: '870px' }}
        sx={{
          position: 'relative',
          borderRadius: 2,
          p: 2,
          aspectRatio: '16 / 9',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-40px',
            right: '5%',
            zIndex: 2,
            display: { xs: 'none', sm: 'flex' },
            gap: 4,
            justifyContent: 'center',
            width: '35%',
          }}
        >
          <IconButton
            onClick={handlePrevious}
            sx={{
              width: '40px',
              display: 'grid',
              placeItems: 'center',
              bgcolor: '#C7FD06',
              color: 'white',
              '&:hover': { bgcolor: '#C7FD06' },
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              width: '40px',
              display: 'grid',
              placeItems: 'center',
              bgcolor: '#C7FD06',
              color: 'white',
              '&:hover': { bgcolor: '#C7FD06' },
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '498px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.5s ease',
              transform: `translateX(${currentTranslateX}px)`,
              gap: `${gapSize}px`,
              py: 2,
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {properties.map((property) => (
              <Box
                key={property.id}
                component="img"
                src={property.image}
                alt={property.title}
                // onClick={() => handleImageClick(property.id)}
                sx={{
                  width: `${itemWidth}px`,
                  objectFit: 'cover',
                  flexShrink: 0,
                  boxShadow: '4px 4px 7px rgba(0, 0, 0, 0.2)',
                }}
              />
            ))}
          </Box>
        </Box>

        <Box
          className={`overlay`}
          sx={{
            position: 'absolute',
            top: { xs: 'auto', sm: '22%' },
            bottom: { xs: '-100px', sm: 'auto' },
            left: { xs: '50%', sm: 'auto' },
            transform: { xs: 'translateX(-50%)', sm: 'translateX(40px)' },
            right: { xs: 'auto', sm: '5%' },
            width: { xs: '90%', sm: '432px' },
            maxWidth: '90%',
            height: { xs: 'auto', sm: '186px' },
            bgcolor: '#ffffff',
            borderRadius: { xs: '2px', sm: '0 0 53px 0' },
            p: 2,
            boxShadow: '0px 4px 17.5px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            transition: 'opacity 0.5s ease',
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          <Typography variant="h5" gutterBottom style={{ fontWeight: '700' }}>
            {truncateText(properties[currentIndex]?.title || 'Título não disponível', 25)}
          </Typography>
          <Typography variant="body1" paragraph>
            {truncateText(properties[currentIndex]?.description || '', 150) || 'Descrição não disponível'}
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: 'linear-gradient(90deg, #c7fd06 0.31%, #779704 100.31%)',
              color: 'black',
              py: '4px',
            }}
            onClick={handleContactClick}
          >
            Saiba Mais
          </Button>
        </Box>

      </Container>

      <Contact
        openDialog={openContactDialog}
        onCloseDialog={() => setOpenContactDialog(false)}
        initialMessage={contactMessage} // Passa a mensagem personalizada
      />
    </Box>
  );
}
