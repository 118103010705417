import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@mui/material";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import "./style.css"; // Importando o mesmo CSS para os estilos consistentes

const MoreCarousel = ({ title, subTitle, id }: any) => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get(`list-sections/${id}`);
        const allImages = response.data.reduce((acc: any, item: any) => {
          const itemImages = item.images.map((img: any) => ({
            ...img,
            secure_id: item.secure_id,
            property_id: img.property_id,
          }));
          return [...acc, ...itemImages];
        }, []);
        setImages(allImages);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    if (id) {
      fetchImages();
    }
  }, [id]);

  const settings = {
    dots: false, // Dots desativados
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: images.length <= 1 ? 1 : 2.3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: images.length <= 1 ? 1 : 2.3,
          slidesToScroll: 1,
          dots: false, // Dots desativados para este breakpoint
          infinite: images.length > 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false, // Dots desativados para este breakpoint
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false, // Dots desativados para este breakpoint
          infinite: true,
        },
      },
    ],
  };

  const handleImageClick = (secure_id: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Rola até o topo com uma animação suave
    navigate(`/detalhes-do-imovel/${secure_id}`);
  };
  

  return (
    <Container maxWidth="lg" sx={{ marginBottom: "60px", mt: "120px" }}>
      <Typography
        variant="h3"
        component="h2"
        gutterBottom
        className="section-title"
        sx={{justifyContent: {xs: 'center', sm: 'left'}}}
      >
        {title}
      </Typography>
      <Slider {...settings} className="carousel-pd">
        {images.map((img: any, index: any) => (
          <Box
            key={index}
            className="carouselImageContainer"
            onClick={() => handleImageClick(img.secure_id)}
          >
            <img src={`${img.path}`} alt={`Imagem ${index + 1}`} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default MoreCarousel;
