// NavBar.tsx
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Container, Typography, Button, Box, ThemeProvider, useMediaQuery, useTheme, Drawer, IconButton, List, ListItem, ListItemText, Backdrop } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../../assets/images/logo.svg';
import './style.css';
import defaultTheme from '../../Themes/Default';
import { useNavigate } from 'react-router-dom';

const NavBar = ({ refs, openContactDialog }: any) => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollOrNavigate = (section: string, action?: Function) => {
    if (window.location.pathname === '/') {
      action ? action() : handleScrollToSection(refs[section]);
    } else {
      navigate("/", { state: { scrollTo: section } });
    }
  };

  const handleScrollToSection = (ref: any) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToCarousels = () => {
    if (refs.carousel?.current?.[0]) {
      refs.carousel.current[0].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogoClick = () => navigate('/');
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const navItems = [
    { text: 'Imóveis', action: () => handleScrollOrNavigate("carousel", handleScrollToCarousels) },
    { text: 'Parceiros', action: () => handleScrollOrNavigate("partners") },
    { text: 'Depoimentos', action: () => handleScrollOrNavigate("testimonials") },
    { text: 'Contato', action: openContactDialog },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar position="fixed" className={`appBar ${scrolled ? 'appBarScrolled' : ''}`}>
        <Container maxWidth="lg" className="container">
          <Toolbar disableGutters className="toolbar" style={{ justifyContent: 'space-between' }}>
            <Box className="logoBox" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
              <Typography variant="h4" component="div" className="logoTypography">
                <img src={Logo} alt="Logo" className="logoImage" />
              </Typography>
            </Box>
            {isMobile ? (
              <>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
                <Backdrop open={mobileOpen} onClick={handleDrawerToggle} style={{ zIndex: theme.zIndex.drawer - 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
                <Drawer
                  anchor="right"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  PaperProps={{
                    style: { width: '100vw', height: '100svh', padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'start' },
                  }}
                >
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                    <IconButton onClick={handleDrawerToggle} style={{ alignSelf: 'flex-end', marginBottom: '16px' }}>
                      <CloseIcon />
                    </IconButton>
                    <List style={{ width: '100%' }}>
                      {navItems.map((item, index) => (
                        <ListItem button key={index} onClick={() => { item.action(); handleDrawerToggle(); }}>
                          <ListItemText primary={item.text} style={{ textAlign: 'center' }} />
                        </ListItem>
                      ))}
                    </List>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2} width="100%">
                      <Button
                        onClick={openContactDialog}
                        className="custom-button-vext"
                        fullWidth
                        style={{ maxWidth: '200px', marginBottom: '8px' }}
                      >
                        Saiba Mais
                      </Button>
                      <Button
                        onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSc94USgL9ZWSBU6sbtkYVYAoxuhrHn0DWdWqycyoAXmRVrUKg/viewform', '_blank')}
                        className="custom-button"
                        fullWidth
                        style={{ maxWidth: '200px' }}
                      >
                        Seja um Vexter
                      </Button>
                    </Box>
                  </Box>
                </Drawer>
              </>
            ) : (
              <Box display="flex" alignItems="center" width="100%">
                <Box className="navButtons" display="flex" alignItems="center" gap={2} justifyContent="center" flexGrow={1}>
                  {navItems.map((item, index) => (
                    <Button key={index} color="inherit" className="navButton" onClick={item.action}>
                      {item.text}
                    </Button>
                  ))}
                </Box>
                <Box display="flex" gap={1} ml="auto">
                  <Button
                    onClick={openContactDialog}
                    className="custom-button-vext"
                  >
                    <span className="button-text-vext">Saiba Mais</span>
                  </Button>
                  <Button
                    onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSc94USgL9ZWSBU6sbtkYVYAoxuhrHn0DWdWqycyoAXmRVrUKg/viewform', '_blank')}
                    className="custom-button"
                  >
                    <span className="button-text">Seja um Vexter</span>
                  </Button>
                </Box>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default NavBar;