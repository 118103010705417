import { useState, useEffect, useRef } from 'react'
import { Card, CardContent, Typography, Avatar, Rating, Box, Container, Button, useMediaQuery, useTheme } from '@mui/material'
import './style.css';
const testimonials = [
  {
    name: 'Vilson Dieter',
    position: 'Administrador de Empresas',
    company: 'Empresário de Trade Marketing',
    quote: 'Fiz negócio com eles na compra de uma unidade do empreendimento UP Side da Rôgga. Fui muito bem atendido antes, durante e também no pós venda. Eles são fantásticos e respondem prontamente as dúvidas e facilitaram em todo o processo.',
  },
  {
    name: 'Josi e Alan',
    position: 'Administração Financeira',
    company: 'Engenheiro Civil',
    quote: 'Chegamos à Vext através da Lívia, uma corretora muito profissional que entendeu o que procurávamos e nos ofereceu opções que de fato encaixaram com nossas expectativas.',
  },
  {
    name: 'Tamara Rebelo',
    position: 'Cliente Vext',
    company: '',
    quote: 'O corretor nos atendeu de forma excelente, nos orientando cuidadosamente para fazer a melhor escolha. Nos sentimos confiantes durante todo o processo.',
  },
  {
    name: 'Paulo Zerbinato',
    position: 'Empresário',
    company: '',
    quote: 'Gostaria de expressar meu sincero agradecimento aos corretores/amigos Maycon e João, da Vext.',
  }
]

const TestimonialCard = ({ name, position, company, quote }: any) => {
  const initials = name ? name.split(' ').map((n: any) => n[0]).join('') : '?'

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', bgcolor: 'white', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '4px' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Rating name="read-only" value={5} readOnly />
        <Typography variant="body1" sx={{ mt: 2, color: '#333', fontSize: '16px' }}>
          {quote}
        </Typography>
      </CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Avatar sx={{ bgcolor: '#AAAAAA', color: '#fff', mr: 2 }}>{initials}</Avatar>
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{name || 'Anonymous'}</Typography>
          <Typography variant="body2" sx={{ color: '#777' }}>
            {position}{company ? `, ${company}` : ''}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(1) // Start from index 1 because of cloned slides
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerPage = isMobile ? 1 : 3
  const [translateX, setTranslateX] = useState(-100 / itemsPerPage) // Start at the first real slide

  const totalItems = testimonials.length

  // Clone slides at the beginning and end
  const extendedTestimonials = [
    testimonials[testimonials.length - 1],
    ...testimonials,
    testimonials[0]
  ]

  // Touch handling states
  const [isDragging, setIsDragging] = useState(false)
  const startXRef = useRef<number>(0)
  const currentTranslateXRef = useRef<number>(0)
  const animationRef = useRef<number>(0)
  const timerRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    // Reset translateX when currentIndex changes
    setTranslateX(-currentIndex * (100 / itemsPerPage))
  }, [currentIndex, itemsPerPage])

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1)
    }, 5000)
    return () => clearInterval(timerRef.current)
  }, [])

  // Handle infinite looping
  useEffect(() => {
    if (currentIndex === 0) {
      // Jump to the real last slide without transition
      setTimeout(() => {
        setCurrentIndex(totalItems)
        setTranslateX(-totalItems * (100 / itemsPerPage))
      }, 0)
    } else if (currentIndex === totalItems + 1) {
      // Jump to the real first slide without transition
      setTimeout(() => {
        setCurrentIndex(1)
        setTranslateX(-100 / itemsPerPage)
      }, 0)
    }
  }, [currentIndex, totalItems, itemsPerPage])

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true)
    startXRef.current = e.touches[0].clientX
    currentTranslateXRef.current = translateX
    cancelAnimationFrame(animationRef.current)
    clearInterval(timerRef.current)
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return
    const currentX = e.touches[0].clientX
    const deltaX = currentX - startXRef.current
    const percentageDeltaX = (deltaX / window.innerWidth) * 100 * (itemsPerPage)
    setTranslateX(currentTranslateXRef.current + (percentageDeltaX / itemsPerPage))
  }

  const handleTouchEnd = () => {
    setIsDragging(false)
    const movedBy = translateX - currentTranslateXRef.current
    const threshold = 20 / itemsPerPage // Adjust threshold as needed

    if (movedBy < -threshold) {
      // Swiped left
      setCurrentIndex((prevIndex) => prevIndex + 1)
    } else if (movedBy > threshold) {
      // Swiped right
      setCurrentIndex((prevIndex) => prevIndex - 1)
    } else {
      // Return to the current index
      setTranslateX(-currentIndex * (100 / itemsPerPage))
    }

    // Restart autoplay
    timerRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1)
    }, 5000)
  }

  return (
    <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
      <Container sx={{ maxWidth: '1000px !important', px: { xs: 2, sm: 4, md: 6 } }} >
        <Typography className='section-title'>
          Depoimentos
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph sx={{ fontSize: '18px', color: '#666' }}>
          Experiências que Inspiram e Conquistas Compartilhadas
        </Typography>

        <Box
          sx={{ position: 'relative', overflow: 'hidden', my: 4, mx: { xs: -2, sm: -4, md: -6 } }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Box
            sx={{
              display: 'flex',
              transition: isDragging ? 'none' : 'transform 0.5s ease-in-out',
              transform: `translateX(${translateX}%)`,
              py: 4
            }}
          >
            {extendedTestimonials.map((testimonial, index) => (
              <Box
                key={index}
                sx={{
                  flexShrink: 0,
                  width: `${100 / itemsPerPage}%`,
                  px: 2,
                  py: 4,
                }}
              >
                <TestimonialCard {...testimonial} />
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          {[...Array(totalItems)].map((_, index) => (
            <Button
              key={index}
              variant={
                index + 1 === currentIndex ||
                  (currentIndex === 0 && index === totalItems - 1) ||
                  (currentIndex === totalItems + 1 && index === 0)
                  ? 'contained'
                  : 'outlined'
              }
              size="small"
              sx={{
                minWidth: 'auto',
                width: 12,
                height: 12,
                p: 0,
                mx: 0.5,
                borderRadius: '50%',
                borderColor: index + 1 === currentIndex ? '#C7FD06' : 'grey.400',
                bgcolor: index + 1 === currentIndex ? '#C7FD06' : '#8D8D8D',
                '&:hover': {
                  bgcolor: index + 1 === currentIndex ? '#B3E305' : '#8D8D8D',
                  borderColor: index + 1 === currentIndex ? '#B3E305' : 'grey.600',
                },
              }}
              onClick={() => setCurrentIndex(index + 1)}
              aria-label={`Go to testimonial ${index + 1}`}
            >
              <Box component="span" sx={{ display: 'none' }}>
                Testimonial {index + 1}
              </Box>
            </Button>
          ))}
        </Box>

        <Box sx={{ textAlign: 'center', my: 6 }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: '#C7FD06',
              color: 'black',
              '&:hover': { bgcolor: '#B3E305' },
            }}
            onClick={() => window.open('https://maps.app.goo.gl/wKPr5idcDfpnqbcG8', '_blank')}
          >
            Avaliar agora
          </Button>
        </Box>
      </Container>
    </Box>
  )
}