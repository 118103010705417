import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const CookiePolicy = () => {
    return (
        <Box sx={{ bgcolor: '#f9f9f9', py: 6 }}>
            <Container maxWidth="md">
                <Typography variant="h4" component="h1" sx={{ fontWeight: 700, mb: 4, textAlign: 'center' }}>
                    Política de Cookies
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    A <strong>Vext</strong> está comprometida em respeitar a sua privacidade e assegurar a transparência sobre como utilizamos cookies e tecnologias similares em nosso site. Esta Política de Cookies explica o que são cookies, como os utilizamos e como você pode gerenciá-los.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    1. O que são cookies?
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Cookies são pequenos arquivos de texto armazenados no seu dispositivo (computador, smartphone, tablet) quando você visita um site. Eles são amplamente utilizados para fazer os sites funcionarem ou funcionarem de maneira mais eficiente, além de fornecer informações aos proprietários do site.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    2. Como usamos os cookies?
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Utilizamos cookies para diversas finalidades:
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 1 }}>
                    - **Cookies Essenciais:** Necessários para o funcionamento do site, permitindo que você navegue e utilize os recursos essenciais.
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 1 }}>
                    - **Cookies de Desempenho e Análise:** Coletam informações sobre como os visitantes utilizam nosso site, ajudando-nos a melhorar a experiência do usuário. Isso inclui serviços como Google Analytics e Microsoft Clarity.
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 1 }}>
                    - **Cookies de Funcionalidade:** Permitem que o site se lembre de suas escolhas (como preferências de idioma) para oferecer uma experiência mais personalizada.
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 1 }}>
                    - **Cookies de Publicidade e Marketing:** Usados para tornar as mensagens publicitárias mais relevantes para você. Utilizamos ferramentas como Google Ads e Facebook Pixel para rastrear a eficácia de nossas campanhas e personalizar o conteúdo que você vê.
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 1 }}>
                    - **Cookies de Rastreamento:** Ajudam a monitorar a sua interação com nosso site e com sites de terceiros, permitindo-nos oferecer conteúdos e anúncios alinhados aos seus interesses.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    3. Cookies de terceiros
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Alguns cookies podem ser definidos por terceiros em nosso site, como:
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, mb: 1 }}>
                    - Google Ads  
                    - Facebook Pixel  
                    - Microsoft Clarity  
                    - Google Analytics  
                    - Google Tag Manager
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Esses terceiros podem utilizar cookies para coletar informações sobre suas atividades online ao longo do tempo e em diferentes sites.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    4. Consentimento e gestão de cookies
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Ao acessar nosso site, você tem a opção de aceitar ou recusar o uso de cookies através de uma caixa de diálogo. Caso deseje alterar suas preferências de cookies, você pode fazê-lo a qualquer momento ajustando as configurações do seu navegador. Note que a desativação de certos cookies pode afetar a funcionalidade do site.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    5. Links para sites de terceiros
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Nosso site pode conter links para outros sites que não são operados por nós. Não temos controle sobre o conteúdo e as práticas desses sites e não nos responsabilizamos por suas respectivas políticas de privacidade e de cookies.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    6. Legislação aplicável
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Estamos comprometidos com a conformidade das leis de proteção de dados, incluindo a Lei Geral de Proteção de Dados Pessoais (LGPD) no Brasil. Para mais detalhes sobre como tratamos seus dados pessoais, consulte nossa <Link href="/politica-de-privacidade" underline="hover">Política de Privacidade</Link>.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    7. Atualizações desta política
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Podemos atualizar esta Política de Cookies periodicamente. Recomendamos que você reveja esta página regularmente para se manter informado sobre quaisquer mudanças.
                </Typography>

                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
                    8. Contato
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Se você tiver dúvidas ou preocupações sobre nossa utilização de cookies, entre em contato conosco em <Link href="mailto:contato@vextimob.com.br" underline="hover">contato@vextimob.com.br</Link>.
                </Typography>

                <Typography variant="body1" sx={{ mt: 4, color: 'gray' }}>
                    Data de vigência: Esta política é efetiva a partir de <strong>22 de novembro de 2024</strong>.
                </Typography>
            </Container>
        </Box>
    );
};

export default CookiePolicy;