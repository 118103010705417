import { Box, Button, Container, Typography } from '@mui/material';
import './style.css';
import SvgContainer from '../../../components/SvgContainer';

export function Matchmaker({ refs, openContactDialog }: any) {
    return (
        <Box sx={{
            mt: { xs: '0px', sm: '100px' },
            mb: { xs: '30px', sm: '70px' },
            position: 'relative'
        }}>
            {/* Novo Container para SVGs */}
            <SvgContainer />
            <Box className="newSection">
                <Container maxWidth="lg" className="newSectionContainer">
                    <Box
                        className="backgroundImage"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/banner.png)`,
                        }}
                    />
                    <Typography variant="h3" component="h2" gutterBottom className="sectionTitle" color="white">
                        Procurando o melhor investimento?
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom className="sectionSubtitle" color="white">
                        Você não precisa fazer isso sozinho.
                    </Typography>
                    <Box className="sectionButtons">
                        <Button
                            variant="contained"
                            color="primary"
                            className='contact'
                            onClick={openContactDialog}
                        >
                            Falar com um Vexter
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}