import React from 'react';
import { Box, Container, Typography, Link, Divider, Grid } from '@mui/material';
import { Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const socialLinks = [
        { Icon: Instagram, url: 'https://www.instagram.com/vextimob/' },
        { Icon: LinkedIn, url: 'https://www.linkedin.com/company/vext-imob/' },
        { Icon: YouTube, url: 'https://www.youtube.com/channel/UCR0DVf29XU-SCIBwHXGSaMw' }
    ];

    return (
        <Box component="footer" sx={{ bgcolor: '#000', color: '#fff', py: 4 }}>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="div" sx={{ fontWeight: '700', display: 'flex', justifyContent: 'start' }}
                            onClick={() => navigate('/')}
                        >
                            <img src={`${process.env.PUBLIC_URL}/logo_branca.png`} alt="Logo VEXT" width={'80px'} style={{ cursor: 'pointer' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, gap: 2, flexWrap: 'wrap' }}>
                        {socialLinks.map((link, index) => (
                            <Link key={index} href={link.url} target="_blank" color="inherit">
                                <link.Icon sx={{ color: '#fff', fontSize: '24px' }} />
                            </Link>
                        ))}
                    </Grid>
                </Grid>

                <Divider sx={{ bgcolor: 'grey.700', my: 3 }} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: { xs: 1, sm: 0 },
                    }}
                >
                    {/* Informações de endereços e CRECI */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, textAlign: { xs: 'center', sm: 'left' } }}>
                        {['Vext - Piçarras | CRECI nº 7701', 'Vext - Joinville | CRECI nº 8308'].map((text) => (
                            <Typography key={text} variant="body1" color="inherit">
                                {text}
                            </Typography>
                        ))}
                    </Box>

                    {/* Links de Política */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: { xs: 'center', sm: 'flex-end' },
                            textAlign: { xs: 'center', sm: 'right' },
                        }}
                    >
                        <Link
                            href="/politicas-de-cookies"
                            color="inherit"
                            underline="hover"
                            sx={{ fontSize: '14px' }}
                            target="_blank"
                        >
                            Políticas de Cookies
                        </Link>
                        <Link
                            href="/politicas-de-privacidade"
                            color="inherit"
                            underline="hover"
                            sx={{ fontSize: '14px' }}
                            target="_blank"
                        >
                            Políticas de Privacidade
                        </Link>
                        <Link
                            href="/termos-de-uso"
                            color="inherit"
                            underline="hover"
                            sx={{ fontSize: '14px' }}
                            target="_blank"
                        >
                            Termos de uso
                        </Link>

                    </Box>
                </Box>

                {/* Direitos Reservados */}
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ mt: 2, color: 'grey.500', textAlign: 'center' }}
                >
                    © 2024 VEXT IMOB. Todos os direitos reservados.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;