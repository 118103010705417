import React from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@mui/material";
import './style.css';

const Carousel = ({ images }: any) => {
  if (!images || images.length === 0) {
    return <Typography variant="body1" sx={{ textAlign: 'center' }}>Não há imagens</Typography>;
  }

  const settings = {
    dots: false,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: images.length <= 1 ? 1 : 2.3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: images.length <= 1 ? 1 : 2,
          slidesToScroll: 1,
          infinite: images.length > 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <Container maxWidth="lg" sx={{ my: '60px', mt: '120px' }}>
      <Slider {...settings} className="carousel-pd">
        {images.map((img: any, index: any) => (
          <Box 
            key={index} 
            className="carouselImageContainer" 
            sx={{
              textAlign: 'center',
              fontSize: {
                xs: '12px', // Tamanho para telas pequenas
                sm: '14px', // Tamanho para tablets
                md: '16px', // Tamanho para desktops
              },
            }}
          >
            <img src={`${img.path}`} alt={`Imagem ${index + 1}`} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default Carousel;